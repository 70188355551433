<template>
  <div>
    <NGrid :x-gap="15" :cols="24">
      <NGi :span="8">
        <MaterialDir
          ref="materialDirRef"
          read-only
          :dir-data="chapters"
          @editing-section-change="handleEditingSectionchange"
        />
      </NGi>
      <NGi :span="16">
        <MaterialSection
          :editing-section="editingSection"
          :chapter-section-type="chapterSectionType"
        />
      </NGi>
    </NGrid>
    <ExamSettings
      ref="examSettingsRef"
      style="margin-top: 15px;"
    />
    <CourseFile
      ref="courseFileRef"
      style="margin-top: 15px;"
      partName="课程大纲"
      :acceptArr="['.jpg', '.jpeg', '.png', '.pdf', '.mp4', '.mp3']"
    />
    <CourseFile
      ref="teachingMaterialRef"
      style="margin-top: 15px;"
      partName="教辅材料"
      :acceptArr="['.zip', '.rar', '.7z']"
    />
    <NSpace size="large" style="margin-top: 10px;">
      <NButton size="large" style="width: 120px;" @click="router.push('index');">返回</NButton>
      <NSpace>
        <NButton size="large" style="width: 120px;" @click="emit('step-pre');">上一步</NButton>
        <NButton size="large" type="primary" style="width: 120px;" @click="handleClickDone">完成</NButton>
      </NSpace>
    </NSpace>

    <BeforeSubmitUpload
      ref="beforeSubmitUploadRef"
      @all-file-success="handleAllFileSuccess"
      @upload-error="handleUploadError"
    />
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, nextTick } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { useMessage } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';
  import MaterialDir from '@/views/material/index/build/components/MaterialDir.vue';
  import MaterialSection from '@/views/material/index/build/components/MaterialSection.vue';
  import ExamSettings from './ExamSettings.vue';
  import CourseFile from './CourseFile.vue';
  import BeforeSubmitUpload from '@/components/BeforeSubmitUpload/BeforeSubmitUpload.vue';

  import {
    GetCourseDetail,
    PostCourseUpdate,
    PostCourseCreate
  } from '@/api/course.js';

  import {
    getChapters
  } from '@/views/material/index/build/components/tool-fns.js';
  import {
    processCourseResData,
    processChapterList
  } from './process-course-res-data.js';

  import { resStatusEnum } from '@/enumerators/http.js';

  const emit = defineEmits(['step-pre']);

  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);
  const router = useRouter();
  const route = useRoute();
  const message = useMessage();

  const courseId = route.query.id;

  const materialDirRef = ref(null);
  const examSettingsRef = ref(null);
  const courseFileRef = ref(null);
  const teachingMaterialRef = ref(null);
  const beforeSubmitUploadRef = ref(null);
  const chapters = ref([]);
  const editingSection = ref({});
  const chapterSectionType = ref('');
  const handleEditingSectionchange = params => {
    editingSection.value = params.data;
    chapterSectionType.value = params.chapterSectionType;
  };
  
  const initData = () => {
    loading.value = true;
    GetCourseDetail({
      'Course[id]': courseId,
      'Course[type]': 2
    }).then(res => {
      if (res.code === SUCCESS) {
        try {
          chapters.value = processChapterList(processCourseResData(res.data.list));
        } catch (e) {
          console.log(e);
        }
        nextTick(() => {
          materialDirRef.value.expandAllChapters();
        });
        examSettingsRef.value.initData(res.data.exam_list);
        courseFileRef.value.initData(res.data.upload_info);
        teachingMaterialRef.value.initData(res.data.teaching_upload_info);
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  };

  let reqChapters = [];
  let reqCourseOutlineArr = [];
  let reqTeachingMaterialArr = [];
  let hasFileMudules = [];
  const handleClickDone = () => {
    examSettingsRef.value.validate().then(errMsg => {
      if (errMsg) {
        message.error(errMsg);
      } else {
        const courseFileList = courseFileRef.value.getFiles();
        const teachingMaterialArr = teachingMaterialRef.value.getFiles();
        
        reqChapters = getChapters(chapters.value, 'course');
        hasFileMudules.splice(0);

        const reqFilesSet = [];

        reqChapters.forEach(chapter => {
          chapter.model_list.forEach(chapterModule => {
            const { fileList } = chapterModule;
            if (Array.isArray(fileList) && fileList.length > 0) {
              hasFileMudules.push(chapterModule);
              reqFilesSet.push(fileList);
            } else {
              delete chapterModule.fileList;
            }
          });
          chapter.section_list.forEach(section => {
            section.model_list.forEach(sectionModule => {
              const { fileList } = sectionModule;
              if (Array.isArray(fileList) && fileList.length > 0) {
                hasFileMudules.push(sectionModule);
                reqFilesSet.push(fileList);
              } else {
                delete sectionModule.fileList;
              }
            });
          });
        });

        reqFilesSet.unshift(courseFileList, teachingMaterialArr);

        beforeSubmitUploadRef.value.startUpload(reqFilesSet);
      }
    });
  };



  const submitData = () => {
    loading.value = true;
    const reqData = {
      'Course[id]': courseId,
      'Course[type]': 1,
      'Course[list]': reqChapters,
      'Course[exam]': examSettingsRef.value.getData(),
    };
    reqCourseOutlineArr.length > 0 && (reqData['Course[upload]'] = reqCourseOutlineArr);
    reqTeachingMaterialArr.length > 0 && (reqData['Course[teaching_upload]'] = reqTeachingMaterialArr);
    PostCourseUpdate(reqData).then(res => {
      if (res.code === SUCCESS) {
        message.success('保存成功');
        router.push('index');
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  };

  function addFileReqData(fileIds) {
    hasFileMudules.forEach(moduleData => {
      moduleData.fileList.forEach(() => {
        moduleData.list.push({
          type: '3',
          ids: fileIds.shift()
        });
      });
      delete moduleData.fileList;
    });
  }
  function getFileIds(list) {
    loading.value = true
    PostCourseCreate({
      'Course[upload]': list
    }).then(({ code, data }) => {
      if (code === SUCCESS) {
        addFileReqData(data);
        setTimeout(submitData, 0);
      }
    }).finally(() => {
      loading.value = false
    });
  }
  function handleAllFileSuccess(filesSet) {
    reqCourseOutlineArr.splice(0);
    reqTeachingMaterialArr.splice(0);
    reqCourseOutlineArr = filesSet.shift().map(({
      fileUrl: file_url,
      name: file_name,
      size: file_size,
      ext: extension,
      fileType: file_type,
      videoId: video_id
    }) => ({
      file_url,
      file_name,
      file_size,
      extension,
      file_type,
      video_id
    }));
    reqTeachingMaterialArr = filesSet.shift().map(({
      fileUrl: file_url,
      name: file_name,
      size: file_size,
      ext: extension,
      fileType: file_type,
      videoId: video_id
    }) => ({
      file_url,
      file_name,
      file_size,
      extension,
      file_type,
      video_id
    }));

    if (Array.isArray(filesSet) && filesSet.length > 0) {
      getFileIds(filesSet.flat().map(({
        ext,
        fileType,
        fileUrl,
        name,
        size,
        videoId
      }) => ({
        file_url: fileUrl,
        file_name: name,
        file_size: size,
        extension: ext,
        file_type: fileType,
        video_id: videoId
      })));
    } else {
      submitData();
    }
  };
  const handleUploadError = () => {
    loading.value = false;
    console.log('有文件上传失败，返回');
  };

  defineExpose({
    initData
  });
</script>

<style lang="less" scoped>

</style>