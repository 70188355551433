<template>
  <div>
    <NCard
      :title="partName"
      size="small"
      :segmented="{ content: 'hard' }"
    >
      <div 
        style="position: absolute; left: 135px; line-height: 32px; font-size: 12px; color: #999;"
      >支持文件格式：{{ acceptArr.join('、') }}；文件大小不超过 300MB</div>
      <AddLocalFile
        ref="addLocalFileRef"
        style="width: 50%;"
        triggerType="external"
        :accept="acceptArr.join(',')"
        :max-bytes-size="300 * 1024 * 1024"
        :init-list="initUploadList"
        multiple
      >
        <template #upbtn>
          <NButton>
            <template #icon>
              <NIcon><FileUploadOutlined /></NIcon>
            </template>
            上传文件
          </NButton>
        </template>
      </AddLocalFile>
    </NCard>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { NButton, NIcon } from 'naive-ui';
  import { FileUploadOutlined } from '@vicons/material';

  import AddLocalFile from '@/components/AddLocalFile/AddLocalFile.vue';

  import { generateEditAddLocalFileItem } from '@/components/AddLocalFile/generate-data.js';

  defineProps({
    partName: {
      type: String,
      required: true
    },
    acceptArr: {
      type: Array,
      default: function () { return []; }
    }
  });

  const addLocalFileRef = ref(null);
  const initUploadList = ref([]);

  defineExpose({
    initData: (arr) => {
      initUploadList.value = Array.isArray(arr) ?
        arr.map(item => generateEditAddLocalFileItem(item)) :
        [];
    },
    validate: () => new Promise(resolve => {
      if (addLocalFileRef.value.getFileList().length > 0) {
        resolve();
      } else {
        resolve(`请上传${partName}`);
      }
    }),
    getFiles: () => addLocalFileRef.value.getFileList()
  });
</script>